:root {
  --font-primary: "Open Sans";
  --font-secondary: "Inter";
  --font-alternative: "Poppins", sans-serif;
}
.App {
  text-align: center;
}
.side-nav-header {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  gap: 12px;

  .logo {
    width: 45px;
    height: 45px;
  }

  .name {
    font-family: var(--font-primary);
    font-size: 14px;
    font-weight: 700;
    line-height: 19.07px;
    text-align: left;
  }

  .email {
    font-family: var(--font-primary);
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
  }

  .nav-search {
    padding: 8px 16px;
    gap: 10px;
    border-radius: 8px 0px 0px 0px;
    opacity: 0;
    background: #f5f5f5;
  }
}
.side-nav-bar-menu .MuiListItemText-primary {
  font-family: var(--font-primary);
  font-size: 15px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  text-wrap: nowrap;
  color: #09090a;
}
.side-nav-bar-menu .MuiListItemText-primary:hover {
  font-weight: 500;
}
.side-nav-bar-menu .MuiListItem-root,
.side-nav-bar-menu .MuiListItemButton-root {
  cursor: pointer;
  padding: 8px 16px;
  margin-top: 4px;
  gap: 16px;
  border-radius: 8px;
  min-width: initial;
}
.side-nav-bar-menu .MuiListItemButton-root:hover {
  background: "#f5f5f5" !important;
}
.side-nav-bar-menu .MuiListItemIcon-root {
  min-width: initial;
}

/* .side-nav-bar-menu .MuiListItem-root:active {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #47A0DE;
} */
.search-container {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  background-color: #f5f5f5;
  padding: 8px 16px 8px 16px;
  gap: 10px;
  border-radius: 8px;
  margin-bottom: 18px;
  height: 46px;
}

.search-icon {
  color: #555;
  margin-right: 8px;
}

.search-input {
  border: none;
  outline: none;
  background-color: transparent;
  flex: 1;
  width: 100%;
  font-family: var(--font-primary);
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
}
.super-app-theme--header {
  font-weight: 900;
}
.drawer-container {
  padding: 24px;
  gap: 18px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.drawer-container-container .toolbar-container {
  background-color: #ffffff;
  padding: 0;
}
.text-input-label {
  color: #333333 !important;
  font-family: var(--font-alternative) !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-align: left !important;
}
.custom-form-control .MuiInputBase-root .MuiInputBase-input:focus {
  box-shadow: rgb(52 58 60 / 48%) 0 0 0 0.025rem;
  border-color: #9e9e9e;
  label + .MuiInputBase-root-MuiInput-root-MuiSelect-root {
    margin-top: 24px;
  }

  .css-b12mt1-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
}

.custom-form-control,
.custom-form-control *,
.custom-form-control input {
  width: 100%;
}

/* Login */
.login-container {
  background: radial-gradient(
    100.57% 70.71% at 50% 50%,
    #2f7794 0%,
    #3682a1 32.73%,
    #4495b7 74.03%
  );
  display: flex;
  justify-content: space-around;
  height: 100vh;
}
.login-form-container {
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 32px;
  gap: 10px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto 40px;
  max-width: 400px;
}
/* Tablet devices (768px and below) */
@media (max-width: 768px) {
  .login-form-container {
    max-width: 380px;
  }
}

/* Mobile devices (480px and below) */
@media (max-width: 480px) {
  .login-form-container {
    max-width: 290px;
  }
}

.login-form-container .MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #333333 !important;
  font-family: var(--font-alternative) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-align: left !important;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-root-MuiInput-root-MuiSelect-root {
  margin-top: 24px;
}
.input-select {
  border-radius: 4px;
  position: relative;
  background-color: #f3f6f9;
  border: 1px solid;
  border-color: #e0e3e7;
  font-size: 16px;
  width: auto;
  padding: 10px 12px;
  .MuiSelect-select {
    padding: 0;
  }
}
.generate-data-table .MuiSelect-select {
  padding: 10px 30px;
}

/* Style for WebKit-based browsers (Chrome, Safari) */
.drawer-container {
  height: 100vh; /* Ensure the drawer-container takes full height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.drawer-container::-webkit-scrollbar {
  width: 8px; /* Set scrollbar width */
}

.drawer-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background of the scrollbar track */
}

.drawer-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar */
  border-radius: 16px; /* Rounded corners */
}

.drawer-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Change color when hovering */
}

/* Scrollbar styling for Firefox */
.drawer-container {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}
.recent-changes-container {
  padding: 16px;
  border-radius: 5px !important;
  background-color: #f6f6f6 !important;
  box-shadow: none !important;
  min-width: 260px;
}
.login-form-container
  span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.MuiTypography-root {
  color: #64748b !important;
}
.MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}
.project-allocation-heading {
  font-family: var(--font-secondary) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 19.36px !important;
  text-align: left !important;
  color: #000000 !important;
}
.project-allocation-value {
  font-family: var(--font-alternative) !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 22.5px !important;
  color: #636363 !important;
}
.status-count-stats .MuiGrid2-root p:first-child {
  font-weight: 600;
  font-size: 16px;
  font-family: var(--font-primary);
  color: #000000;
}
.status-count-stats .MuiGrid2-root p:last-child {
  font-weight: 500;
  font-size: 15px;
  font-family: var(--font-alternative);
  color: #636363;
}
.status-count-stats .MuiGrid2-root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.upload-modal-control .MuiButtonBase-root {
  border: 1px solid #636363;
}
.upload-modal-control .MuiButtonBase-root:hover {
  border: 1px solid #47a0de;
  background-color: #47a0de;
  color: white;
}
